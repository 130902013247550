/*
 * @Author: Mirror
 * @Date: 2024-07-10 11:09:11
 * @LastEditors: Mirror
 * @LastEditTime: 2024-07-11 13:47:24
 * @Description:
 */
/*
 * @Author: Mirror
 * @Date: 2024-07-10 11:09:11
 * @LastEditors: Mirror
 * @LastEditTime: 2024-07-11 13:45:47
 * @Description:
 */
import tie from "./tphcbtk.js";
import wmsdd from "./wmsdd.js";
export function getCurrentMusicDetail(index) {
  const list = [
    {
      id: 2090581422,
      title: "铁皮火车不停开",
      cover:
        "https://p2.music.126.net/QdF0rFj1VAli3OpptKU6SQ==/109951168980173340.jpg",
      length: "04:20",
      artist: "蒋敦豪",
      lyric: tie,
      url: "https://music.163.com/song/media/outer/url?id=2090581422.mp3",
    },
    {
      id: 2096445637,
      title: "我们宿舍的",
      cover:
        "https://p2.music.126.net/gYAyQ2pzd491RLMcitRqHQ==/109951169029305744.jpg",
      length: "03:19",
      artist: "鹭卓/卓沅/陈少熙/王一珩OneSD",
      lyric: wmsdd,
      url: "https://music.163.com/song/media/outer/url?id=2096445637.mp3",
    },
  ];
  return list[index];
}
