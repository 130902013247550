const str = `[00:17.53]我们梦到 铁皮火车驶过山海
[00:25.16]一路向城市边缘不停开
[00:33.17]那班列车 终有天将乘着烟霭
[00:41.15]载着自由的魂魄去穹隆外
[00:49.46]我们虽时常 提及那景象
[00:53.14]却不知该如何细讲
[00:57.40]如同在异乡 谈论着故乡
[01:01.05]多么心驰神往
[01:04.81]那被滞留在人间的旅客
[01:09.11]沿铁轨向远方求索
[01:13.15]枕木似阶梯在大地横卧
[01:17.39]无法休憩片刻
[01:20.51]从梦境走到繁琐的生活
[01:25.01]该消磨的任它消磨
[01:29.14]该紧握的也请永远紧握
[01:33.23]等待那一班车
[01:53.53]我们梦到 火车路过现实站台
[02:01.38]漫天尘沙席卷着吹过来
[02:09.54]那班列车 终有天将穿过虹彩
[02:17.42]来填补此前旅途所有苍白
[02:25.36]我们虽时常 提及那景象
[02:29.11]却不知该如何细讲
[02:33.32]如同在异乡 谈论着故乡
[02:37.28]多么心驰神往
[02:42.78]那铁皮火车依旧巡游着
[02:47.11]拖着烟尾巴疾驰过
[02:51.18]在皮肤下在胸腔在骨骼
[02:55.33]不间断地穿梭
[02:58.65]摩擦的轰鸣心跳般起落
[03:03.22]像誓词被一再诉说
[03:07.05]多年后那声音不会减弱
[03:11.10]仍在耳畔响彻`;
export default str;
