/*
 * @Author: Mirror
 * @Date: 2024-07-10 09:00:02
 * @LastEditors: Mirror
 * @LastEditTime: 2024-07-10 11:02:10
 * @Description: 
 */
import {Button,Popup  } from 'vant';
// 放入数组中
let plugins=[
   Button,Popup 
]
export default function getVant(app){
    plugins.forEach((item)=>{
        return app.use(item)
    })
}
