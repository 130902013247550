/*
 * @Author: Mirror
 * @Date: 2024-07-11 13:50:06
 * @LastEditors: Mirror
 * @LastEditTime: 2024-07-12 09:16:44
 * @Description: 
 */
import { createRouter, createWebHistory } from "vue-router";
import Index from "@/views/index.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  // 其他路由配置
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production" ? "/" : "/"
  ),
  routes,
});

export default router;
