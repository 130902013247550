export function getPlayList() {
  const list = [
    // {
    //   id: 1,
    //   title: "tie",
    //   cover:
    //     "https://p2.music.126.net/QdF0rFj1VAli3OpptKU6SQ==/109951168980173340.jpg",
    //   length: "04:20",
    //   artist: "jiang",
    //   lyric: tie,
    // },
    // {
    //   id: 2,
    //   title: "tie",
    //   cover:
    //     "https://p2.music.126.net/QdF0rFj1VAli3OpptKU6SQ==/109951168980173340.jpg",
    //   length: "04:20",
    //   artist: "jiang",
    //   lyric: tie,
    // },
  ];
  return list;
}
