import { createStore } from "vuex";
import { getCurrentMusicDetail } from "@/assets/data/musicList.js";
import { getPlayList } from "@/assets/data/playList.js";
export default createStore({
  state: {
    // playList: [
    // {
    //   //播放列表
    //   al: {
    //     id: 2090581422,
    //     name: "铁皮火车不停开",
    //     pic: 109951164966568500,
    //     // https://p2.music.126.net/QdF0rFj1VAli3OpptKU6SQ==/109951168980173340.jpg
    //     picUrl:
    //       "https://p2.music.126.net/QdF0rFj1VAli3OpptKU6SQ==/109951168980173340.jpg",
    //     pic_str: "109951168980173340",
    //   },
    //   id: 2090581422,
    //   name: "铁皮火车不停开",
    //   ar: [{ name: "蒋敦豪" }],
    // },
    // ],
    playList: [],
    playListIndex: 1, //默认下标为0
    isbtnShow: true, //暂停按钮的显示
    detailShow: false, //歌曲详情页的显示
    lyricList: {}, //歌词
    currentTime: 0, //当前时间
    duration: 0, //歌曲总时长
    isFooterMusic: true, //判断底部组件是否需要显示
    currentPlay: {},
  },
  mutations: {
    updateCurrentPlay: function (state, value) {
      state.currentPlay = value;
    },
    updateIsbtnShow: function (state, value) {
      state.isbtnShow = value;
    },
    updatePlayList: function (state, value) {
      state.playList = value;
      console.log("state.playList", state.playList);
    },
    updatePlayListIndex: function (state, value) {
      state.playListIndex = value;
    },
    updateDetailShow: function (state) {
      state.detailShow = !state.detailShow;
    },
    updateLyricList: function (state, value) {
      state.lyricList = value;
    },
    updateCurrentTime: function (state, value) {
      // console.log(state.currentTime);
      state.currentTime = value;
    },
    updateDuration: function (state, value) {
      state.duration = value;
    },
  },
  actions: {
    getPlayMusicList: async function (context, value) {
      let list = getPlayList();
      console.log("getPlayList", list);
      context.commit("updatePlayList", list);
    },
    getCurrentPlay: async function (context, value) {
      let res = getCurrentMusicDetail(value);
      console.log("getCurrentPlay", res);
      context.commit("updateCurrentPlay", res);
    },
  },
  modules: {},
});
